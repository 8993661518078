@import '../../styles/base.scss';

.live-tv {
  max-width: $desktop-max-width;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.liveTv-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 123px;
  padding-left: 265px;
  padding-right: 250px;
  @media (max-width: $mobile) {
    margin: 0;
    padding: 0;
    padding-left: 0px;
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    //margin-left: 50px;
    margin: 0;
    padding: 0;
    padding-top: 100px;
    padding-left: 20px;
  }
  .tabsHeader {
    width: 836px;
    height: 161px;
    @media (max-width: $mobile) {
      width: auto;
      height: auto;
    }
  }

  .ReactPlayer-container {
    margin-top: 2rem !important;
    margin-left: 0px !important;
    // width: 77%;
    @media (max-width: $mobile) {
      margin: 10px !important;
      width: 100%;
    }
  }

  .ReactPlayer {
    // width: 130%;
    @media (max-width: $mobile) {
      width: -webkit-fill-available;
      // height: 165px;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      // width: 750px;
    }
    [class^='vjs-playback-rate'] {
      display: none;
    }
  }

  .companion_ad_div {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: inherit;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    // position: sticky;
    flex-direction: column;
    // margin-left: 155px;
    @media (max-width: $mobile) {
      margin-left: 0;
    }
    div[id*='google_ads_iframe']:empty {
      display: none;
    }
  }
  .tabPanel {
    @media (max-width: $tablet-portrait) {
      padding-right: 1rem;
      // height: 165px;
    }
  }
}

.live-tv .vjs-next-button,
.live-tv .ReactPlayer-container .vjs-playback-rate,
.live-tv .vjs-picture-in-picture-control {
  @media (max-width: $mobile) {
    display: none !important;
  }
}

.live-tv .vjs-next-button {
  display: none !important;
}
