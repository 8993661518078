@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.epg-schedule {
  padding-top: 2rem;
  padding-bottom: 2rem;
  .title {
    color: #fff;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Prompt;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .time {
    color: #aaa;

    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: transparent !important;
  }
  .name {
    // :nth-child(odd) {
    //   background-color: #222;
    // }
    // :nth-child(even) {
    background-color: transparent !important;
    // }
    color: #fff;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .live {
    //padding-left: 63px;
    color: #f20b7e;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .channelName {
    padding-left: 63px;
  }
  .MuiGrid-item {
    min-width: 100%;
  }
  .MuiGrid-item:nth-child(odd) {
    background-color: #222;
  }
  .MuiGrid-item:nth-child(even) {
    background-color: #101010;
  }
  .MuiPaper-root {
    flex-flow: wrap;
    display: flex;
    text-align: left;
    background-color: transparent !important;
  }
  .MuiBox-root {
    padding-top: 2rem;
  }

  @include xs {
    .title {
      font-size: 18px !important;
    }
    .name {
      font-size: 14px !important;
    }
    .time {
      font-size: 12px !important;
    }
    .live {
      font-size: 14px !important;
    }
  }
  @include tab-min {
    padding-right: 1rem;
  }
}
