@import '../../styles/base.scss';
.profile-notification {
  width: 660px;
  &.isMobile {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    height: 100vh;
    background: $black;
    padding: 50px 0px 0px 0px;
    .mobile-header {
      padding: 20px;
      font-family: 'Prompt';
      > .MuiButton-startIcon {
        margin-right: 0;
      }
    }
  }
}

.notf-table {
  align-items: flex-start;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-top: 60px;
  margin-top: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  list-style: none;
  width: 660px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.notf-table li {
  display: flex;
  height: 68px;
  align-items: flex-start;
}

.notf_img_holder {
  width: 66px;
  height: 63px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #525252;
}

.notf_text_holder {
  max-width: 400px;
  min-width: 400px;
}

.img_title {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.notf_title {
  color: white;
  font-family: Prompt;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.notf_body {
  color: white;
  font-family: Prompt;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.notf_date {
  color: white;
  font-family: Prompt;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 30%;
}

.notf_close {
  display: flex;
  width: 14.5px;
  margin-top: 10px;
  align-items: flex-start;
  height: 14.5px;
  border-radius: 6px;
}
