@import '../../../styles/base.scss';
@import '../../../styles/mixins-mediaQuery.scss';

.OTPDialog {
  &-wrapper {
    width: 520px;
    height: 482px;
    flex-shrink: 0;
    box-shadow: 0 0.625rem 1.25rem rgba($black, 0.3);
    border-radius: 12px !important;
    // padding: 66px 100px 66px 101px;
    margin: 2rem;
    text-align: center !important;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: $font-family-default !important;
    background: $white !important;
    font-family: Prompt !important;
  }

  &-column-layout {
    display: flex;
    flex-direction: column;
    font-family: Prompt !important;
  }

  &-confirm-button-div {
    display: flex;
    justify-content: center;
    margin-top: 62px;
    font-family: Prompt !important;
  }

  &-confirm-button {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    width: 162px !important;
    height: 52px !important;
    flex-shrink: 0 !important;
    border-radius: 12px !important;
    background-color: $bright-pink !important;
    border-color: $bright-pink !important;
    font-family: Prompt !important;
  }

  &-timer-message {
    color: $bright-pink;
    font-family: Prompt !important;
  }
}

.otp-phone-number-signup {
  font-size: 18px;
  color: $chinese-black;
  font-family: Prompt !important;
}

.enter-otp {
  color: $chinese-black;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $font-family-default;
  font-size: 26px;
  font-style: normal;
  font-family: Prompt !important;
  font-weight: 600;
  line-height: normal;
}

.otp-sent {
  color: $chinese-black;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $font-family-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-family: Prompt !important;
  line-height: normal;
}

.otp-code {
  color: $chinese-black;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $font-family-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Prompt !important;
  display: flex;
  margin-left: 69px;
  margin-bottom: 5px;
}
.messages {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 0;
  font-family: Prompt !important;
  margin: 0;
  justify-content: center;
  .warning {
    color: $white;
    font-family: Prompt !important;
  }
  .error {
    color: $bright-pink;
    font-family: Prompt !important;
  }
}

@include xs {
  .otp-code {
    margin-left: 0px;
  }
}
