@import '../../../styles/base.scss';
@import '../../../styles/mixins-mediaQuery.scss';

.Login-OTPDialog {
  &-wrapper {
    width: 400px !important;
    border-radius: 12px;
    border: 1px #8b8b8b3f solid;
    // height: 420px !important;
    gap: 0 !important;
    opacity: 1 !important;

    background: $chinese-black !important;

    // Default (desktop) styling
    margin: auto; // Center the dialog in the container
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 600px) {
      // Mobile styling
      width: 355px;
      // height: 420px;
      margin: 1rem auto; // Add margin for spacing on smaller screens
    }

    @media (min-width: 601px) and (max-width: 960px) {
      // Tablet styling
      width: 355px;
      // height: 430px;
      margin: 2rem auto; // Center with spacing for tablets
    }

    .login-form {
      width: 305px;
      display: flex;
      // height: 53px;
      top: 192px;
      left: 32px;
      gap: 0px;
      border-radius: 12px 0px 0px 0px;
      border: 1px 0px 0px 0px;
      .MuiDialogContent-root {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px !important;
        overflow-y: unset;
        padding: unset;
      }
    }
    .MuiTypography-root {
      display: flex;
      line-height: 1.6;
      letter-spacing: 0.0075em;
      font-family: Prompt;
      padding: 16px 24px;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  &-column-layout {
    display: flex;
    flex-direction: column;
  }

  &-confirm-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }

  &-confirm-button {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    width: 162px !important;
    height: 52px !important;
    flex-shrink: 0 !important;
    border-radius: 12px !important;
    border-color: $bright-pink !important;
    margin-left: auto; // Align center
    margin-right: auto;
  }

  &-timer-message {
    color: $bright-pink;
    font-size: 12px;
    font-weight: 500;
  }

  &-timer-message-expired {
    color: #ff3a44;
    font-size: 12px;
    font-weight: 500;
  }
}

.otp-code-login {
  color: $white;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $font-family-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  margin: 0 auto 5px; // Center align
}

.otp-sent-login {
  color: $white;
  font-family: Prompt;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.otp-phone-number {
  font-family: Prompt;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $bright-pink;
}

.enter-otp-login {
  color: $white;
  // text-align: center;
  // font-feature-settings:
  //   'clig' off,
  //   'liga' off;
  // font-family: $font-family-default;
  // font-size: 26px;
  // font-style: normal;
  // font-weight: 600;
  // line-height: normal;
  // display: block;
  // margin: 30px 0 !important;

  width: 217px;
  height: 30px;
  font-family: Prompt;
  font-size: 20px;
  font-weight: 600;
  line-height: 30.24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
