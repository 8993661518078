@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.Carousel {
  &-header {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    @include font(
      $fontFamily: $font-family-default,
      $size: 1.5rem,
      $weight: 700,
      $lh: 1.75rem,
      $color: $ghost-white
    );
  }

  &-slider {
    color: $white;
    @media (max-width: $mobile) {
      margin: 0;
    }

    .slick-list {
      cursor: pointer;
    }

    .slick-track {
      margin: 0;
    }
  }

  &-arrow {
    height: 30%;
  }

  &-arrow::before {
    color: $black;
    font-size: 33px;
  }

  &-arrow-disabled {
    display: none !important;
  }

  &-arrow-left {
    left: 10px;
  }

  &-arrow-right {
    right: 35px;
  }
}

.Carousel-main-content {
  position: relative;
  margin-left: 60px;
  @include xs {
    margin-left: 15px;
  }
}

.Carousel-header {
  font-family: Prompt;
  font-size: 28px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  text-wrap: nowrap;
  @media (max-width: $mobile) {
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.Carousel-content {
  max-width: fit-content;
}

.Carousel-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  vertical-align: middle;
  margin-bottom: 30px;
  overflow-x: scroll;
  height: fit-content;
  white-space: nowrap;
}

.Carousel-content li {
  display: inline-block;
  margin-right: 20px;
}

.banner_div_carousel {
  margin: 10px;
  align-items: center;
  height: auto;
  align-self: center;
  display: flex;
  justify-content: center;
}
