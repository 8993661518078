@import '../../styles/base.scss';
.channel-description {
  height: fit-content;
  // width: 132%;
  min-width: 100%;
  display: grid;
  align-content: center;
  
  @media (max-width: $mobile) {
    //width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .title {
    color: #fff;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: $font-family-default;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $mobile) {
      font-size: 22px;
    }
  }
  .shareDiv {
    // margin-right: -151px;
    float: right;
    padding-top: 10px;
    padding-right: 1rem;
    @media (max-width: $mobile) {
      //   margin: 0;
      //   padding: 0;
      margin-right: 53px;
      float: auto !important;
      margin-right: -30px;
    }
    @media (max-width: $tablet) {
      // margin-right: -120px;
    }
  }
  .description {
    color: #fff;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
