@import '../../styles/base.scss';

.Search-Tile-info {
  height: 10%;
}

.Search-Tile-img {
  width: 110px;
  height: 155px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.search-thumbnail {
  width: 110px;
  height: 155px;
  background-color: $darker-grey;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  background-position: center;
}

.img-src-search {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  align-self: center;
  align-items: center;
}

.Search-Tile-info-metadata {
  margin-left: 1rem;
  margin-left: 1rem;
  height: 155px;
}

.Search-Tile-info-title {
  margin: 0 !important;
  justify-content: center;
  text-overflow: ellipsis;
  display: block;
  width: 186px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 22px;
  color: #fff;
  font-family: Prompt;
  font-weight: 500;
}

.Search-Tile-info-sub-title {
  margin: 0 !important;
  color: #aaaaaa;
  font-family: Prompt;
  font-weight: 400;
  font-size: 18px;
}

.Search-Tile-button {
  margin-top: auto;
  align-items: flex-end;
  cursor: pointer;
}

.Search-Tile-icon {
  fill: $bright-pink !important;
  background-color: transparent;
  width: 18px;
  height: 17px;
  cursor: pointer;
}

.Search-Tile-info-button-text {
  -webkit-line-clamp: 1;
  margin: 0;
  margin-left: 10px;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
}

.Search-Tile-wrapper-button {
  border-radius: 12px;
  align-items: center;
  min-width: 7rem;
  width: 99px;
  justify-content: center;
  cursor: pointer;
  height: 44px;
  background-color: #222222;
  color: #fff;
  font-weight: 400;
  border: 1px solid $bright-pink;
}

.Tile-wrapper-add {
  margin-left: 2rem;
  margin-right: 0;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
