@import '../../styles/base.scss';
@import '../../styles/mixins.scss';

.Loading-indicator {
  width: 100%;
  height: 100%;
  @include flexBox(
    $display: flex,
    $alignItems: center,
    $justifyContent: center
  );
  z-index: 999;
  @include equal-dimensions(100%);

  .spinner {
    @include equal-dimensions(2.25rem);
    border: 0.25rem solid $gray;
    border-top-color: $black;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    animation: spin 3s linear infinite;
  }
}
