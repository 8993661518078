@import '../../styles/base';
@import '../../styles/mixins-mediaQuery.scss';

.main-content-search {
  // margin-left: 250px;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  display: table;
  margin: 0 10%;
}

.Search {
  &-wrapper {
    position: relative;
    background-color: #222222;
    display: flex;
    border-radius: $border-radius-default;
    height: 60px;
    width: 100%;
    flex-direction: row;
    border: none;
    align-items: center;
  }

  &-input {
    border-radius: 12px;
    color: $white;
    border: none;
    width: 100%;
    font-family: 'Prompt';
    margin: 58px 16px 58px 58px;
    background-color: transparent;

    font-weight: 400;
    font-size: 20px;
    line-height: 1.25rem;
    float: left;
  }

  &-icon {
    position: absolute;
    margin: 30px;
    float: left;
    cursor: pointer;

    svg {
      vertical-align: middle;
      fill: #d9d9d9 !important;
    }
  }
}

.search_icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.placeholder_opacity {
  opacity: 40%;
}

.text_opacity {
  opacity: 100%;
}

.clear-button {
  height: 4.6rem;
  align-items: center;
  color: white;
  display: table-cell;
  text-align: left;
  width: 156px;
  font-weight: 400;
  font-size: 16px;
  padding-left: 2rem;
  font-family: 'Prompt';
  cursor: pointer;
}

::placeholder {
  color: white;
  font-family: 'Prompt' !important;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: white;
  font-family: 'Prompt' !important;
}
.Search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1.3em;
  width: 1.3em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}
.Search-input:focus::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: all;
}
@include xs {
  .main-content-search {
    width: fit-content;
  }
  .Search {
    &-wrapper {
      width: fit-content;
    }
  }
}
