@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  background-color: transparent;
  transition: background-color 0.3s ease;
  color: white;
  padding: 0px 50px;
  @media (max-width: $mobile) {
    position: sticky;
  }

  &-left {
    // flex: 0.5;
    justify-content: space-between;
  }
  &-navbar-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-logo {
    width: 144px;
    height: 79.86px;

    img {
      @include equal-dimensions(100%);
      object-fit: cover;
      margin-left: -15px;
      @media (max-width: $tablet) {
        margin: auto;
      }
    }
  }

  &-mobile-menu-icon {
    cursor: pointer;
    width: 25px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > span {
      display: block;
      width: 100%;
      height: 3px;
      background-color: $white;
      border-radius: 50px;
      &:nth-child(2) {
        width: 75%;
      }
    }
  }
  &-nav-menu {
    display: flex;
    padding: 0;
    align-items: baseline;
  }

  &-nav-menu a {
    height: 40px;
    color: $white;
    text-decoration: none;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'Prompt';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-right: 25px;
    text-wrap: nowrap;
    text-transform: uppercase;
    white-space: nowrap;
    &.oneD {
      text-transform: none;
    }
    & > svg {
      transform: translateY(5px);
    }
    @media (max-width: $tablet) {
      margin-right: 10px;
      font-size: 1rem;
    }

    &:hover,
    &.active {
      text-decoration: underline;
      text-underline-offset: 10px;
      text-decoration-color: $bright-pink;
    }
  }
  .profile_image {
    width: 35px;
    border-radius: 100px;
  }

  &-sub-menu {
    overflow: auto;
    max-height: 100vh;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
    font-size: 16px;
    color: white;
    box-sizing: inherit;
    padding-left: 0px;
    // margin-top: 10px;
    background: black;
    padding-block: 10px;
    position: absolute;
    border-radius: 10px;
    min-width: 270px;
    transition: all 0.5s;
    & a {
      margin-right: 0;
      &:hover,
      &.active {
        text-decoration: none;
      }
    }
  }

  &-sub-items {
    // padding: 10px;

    margin-inline: 10px;
    transition: all 0.5s;
    cursor: pointer;

    & > a {
      padding: 10px;
      display: block;
      transition: all 0.5s;
      &:hover,
      &.active {
        background-color: darkgray;
      }
    }
  }

  &-link-color {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-color: $rose;
    &.more {
      // pointer-events: none !important;
      text-decoration: none !important;
    }
  }

  .sign-in-button {
    width: 6rem;
    align-self: center;
    height: 2.125rem;

    span {
      font-family: Prompt;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0px;

      @include flexBox(
        $display: flex,
        $alignItems: center,
        $justifyContent: center
      );
    }
  }
  @media (max-width: $tablet) {
    padding: 0 0 0 20px;
    .header-link-color {
      font-size: 0.95rem;
    }
    .header-logo {
      display: flex;
      img {
        width: 125px;
        height: 75px;
      }
    }
    .lang-selector {
      transform: scale(0.9);
    }
    .search-icon-container {
      transform: scale(0.7) translateX(-10px);
      svg {
        margin-left: 0 !important;
      }
    }
    .sign-in-button {
      width: 5rem;
    }
    .lang-container {
      transform: translateX(-5px);
    }
  }
}

.header.scrolled {
  background-color: rgba(0, 0, 0, 0.8);
}

.profile-button {
  background-color: transparent !important;
  border: none !important;
  color: darkgray;
}

.search-icon-container {
  margin-inline: 1.5rem;
  cursor: pointer;
  display: flex;
  align-self: center;
  align-items: center;
  @media (max-width: $tablet) {
    margin-inline: 5px;
  }
}
.menu-wrapper {
  display: flex;
  @media (max-width: $tablet) {
    margin-left: -30px;
  }
}

@include xs {
  .menu-wrapper {
    position: absolute;
    display: none;
    min-height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-block: 20px;
    &.isMobile {
      display: flex;
      ul {
        display: flex !important;
        margin-left: 3rem;
      }
    }
  }
  .header {
    padding: 0 15px;
    &-nav-menu {
      left: 0;
      flex-direction: column;
      & > li {
        & > a {
          display: block;
        }
      }
    }
    &-sub-menu {
      background-color: rgba(0, 0, 0, 0);
    }
    &-logo {
      width: 144px;
    }
  }
}

.notf_counter {
  height: 16px;
  width: 16px;
  bottom: 30px;
  background-color: #f20b7e;
  border-radius: 50%;
  display: flex;
  justify-items: center;
  left: 50px;
  justify-content: center;
  position: absolute;
  align-items: center;
  text-align: left;
  font-family: Prompt;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}
@include tabletPortrait {
  .header-nav-menu a {
    margin-right: 8px;
    font-size: 20px;
  }
  .flex {
    margin: 0;
  }

  .lang_mobile {
    margin-left: 2rem !important;
    padding-top: 0.5rem !important;
  }
  .header {
    &-logo {
      img {
        @include equal-dimensions(100%);
        object-fit: cover;
        margin-left: -35px;
      }
    }
  }
  .menu-wrapper {
    margin-left: -40px;
  }
  .search-icon-container {
    margin-inline: 0;
  }
}

.version_text {
  font-family: Prompt;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  left: 0;
  line-height: 15px;
  letter-spacing: 0px;
  margin: 10px;
  align-items: flex-start;
  color: #ffffff80;
  justify-content: flex-start;
}

.lang_mobile {
  margin-left: -3rem;
}
