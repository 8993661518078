@import '../../styles/base.scss';

.share_item_holder {
  position: relative;
}

.share-sub-menu {
  background-color: #101010;
  position: absolute;
  border-radius: 8px;
  z-index: 999 !important;
  display: none;
  width: 200px;
  padding-left: 15%;
  list-style: none;
}

.share-label {
  -webkit-line-clamp: 1;
  color: #aaa;
  font-family: Prompt;
  font-size: 12px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-weight: 400;
  margin: 0;
  display: flex;
  text-align: center;
  align-items: center;
}

.share-items {
  margin: 3%;
  cursor: pointer;
}

.share-label-text {
  margin: 5%;
  color: white;
  white-space: nowrap;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: normal;
}

.share-label-icon-text {
  color: #aaa;
  white-space: nowrap;
  font-family: Prompt;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: normal;
  padding-top: 5%;
}

.hide_share {
  display: none;
}

.show_share {
  position: absolute;
  display: block;
}

.white_icon {
  fill: white !important;
  cursor: pointer;
}
