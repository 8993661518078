@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.signup-button {
  width: 100%;
  max-width: 196px;
  height: 55px !important;
  background-color: $bright-pink !important;
  border-color: $bright-pink !important;
  border-radius: 12px !important;
  // gap: 4px !important;
  color: $white !important;

  span {
    color: #fff;
    font-family: 'Prompt';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    @include xs {
      font-size: 16px !important;
    }
  }
  &.disabled {
    opacity: 0.6;
  }
  @include xs {
    // width: 169px !important;
  }
}

.later-button {
  width: 100%;
  max-width: 196px;
  height: 55px !important;
  background-color: #2b2b2b !important;
  border-color: #2b2b2b !important;
  border-radius: 12px !important;
  // gap: 4px !important;
  color: $white !important;

  span {
    color: #fff;
    font-family: 'Prompt';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    @include xs {
      font-size: 16px !important;
    }
  }
  &.disabled {
    opacity: 0.6;
  }
  @include xs {
    // width: 169px !important;
  }
}

.confirm-header {
  color: #ffffff;
  font-family: Prompt !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.confirm-header-signup {
  color: #ffffff;
  font-family: Prompt !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.description {
  color: #ffffff;
  font-family: Prompt !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.22px;
  width: 341px;
}

.page-loader {
  background: #000;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  height: 100vh;
}
