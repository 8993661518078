@import '../../styles/base.scss';
.custom-checkbox input {
  display: none;
}
.custom-checkbox span {
  border: 1px solid #7e8a94;
  float: right;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $anti-flash-white;
  color: $bright-pink;
}
.custom-checkbox-label{
  font-weight: 500;
  font-size: 1rem;
  line-height: 24.19px;
  padding-left: 10px;
  color: #8f8f8f;
}

.custom-checkbox input:checked + span:before {
  content: "✔";
}