@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.profile-detail {
  flex-direction: column;
  height: auto;
  h2 {
    color: $white;
  }
}
.date-picker {
  flex: 0.09;
  transform: translate(0px, 20px);
}
.profile-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-text {
    color: $white;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: $font-family-default;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 5px 0px;
  }
}

.horizontal-line {
  width: 108%;
  height: 1px;
  border: 1px solid #5c5c5c;
  margin: 12px 0 28px -20px;
}

.profile-detail-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label-inactive {
    color: $white;
    text-align: right;
  }
}

.profile-detail-label {
  margin-block: 15px;
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  flex: 1;

  span {
    color: $white;
    opacity: 0.5;
    text-transform: none !important;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: $font-family-default;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.profile-detail-input-edit {
  display: flex;
  width: 405px;
  align-items: center;
  gap: 10px;
  padding-bottom: 8px;
  margin-right: 12px;

  input {
    border-radius: 12px !important;
    border: 1px solid #ababab !important;
    background: #f6f6f6 !important;
    padding: 15px 10px !important;
  }

  :hover {
    border-color: $bright-pink !important;
  }

  :active {
    border-color: $bright-pink !important;
  }

  :focus {
    border-color: $bright-pink !important;
  }
}

.profile-detail-input {
  display: flex;
  width: 405px;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 8px;
  color: $white !important;
  text-align: right;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: $font-family-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-button {
  color: $bright-pink !important;
  background: none !important;
  border: none !important;
  // align-self: center;
  font-size: $font-size-xs !important;
  &:disabled {
    opacity: 0.5;
  }
}

.profile-footer {
  display: flex;
  justify-content: flex-end;
  // margin-block: 20px;
  > button {
    width: 170px;
    padding: 16px 24px;
    // margin-left: 5px;
    &:disabled {
      color: $white;
    }
  }

  .back-profile-button {
    background-color: transparent !important;
    border: 2px solid var(--white, #fff) !important;
    margin-right: 2%;
  }

  .profile-confirm-button {
    background-color: $bright-pink !important;
    color: $white !important;
    font-family: 'Prompt';
    &:disabled {
      color: $white !important;
      opacity: 0.5;
    }
  }
  // &:disabled {
  //   opacity: 0.5;
  // }
}

.profile-radio-button {
  list-style-type: none;
  // margin: 25px 0 0 0;
  padding: 0;
  margin-top: 0px;
  padding-bottom: 30px;
  margin-right: 12px;
}

.profile-radio-button li {
  float: left;
  margin: 0 5px 0 0;
  width: 122px;
  height: 50px;
  position: relative;
  padding: 15px 10px;
  align-items: center;
  gap: 10px;
  margin-right: 7px;
}

.profile-radio-button label,
.profile-radio-button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  background-color: #f6f6f6 !important;
  color: #404040 !important;
}

.profile-radio-button input[type='radio'] {
  opacity: 0.011;
  z-index: 100;
}

.profile-radio-button input[type='radio']:checked + label {
  background: #f6f6f6;
  border-radius: 12px;
}

.profile-radio-button label {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 90;
}

.profile-radio-button label:hover {
  background: #ddd;
}

.date-format {
  display: flex;

  &-edit {
    display: inline-flex;
    padding: 15px 14px 15px 10px;
    align-items: center;
    gap: 66px;
    width: 122px;
    border-radius: 12px;
    border: 1px solid #ababab;
    background: #f6f6f6;
    margin-right: 12px;
    margin-bottom: 8px;
  }

  &-view {
    color: $white;
    // text-align: right;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: $font-family-default;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    border: none;
  }
}

@include xs {
  .profile-detail {
    &.mobile {
      position: absolute;
      background: $white;
      top: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      min-height: 150vh;
      form {
        padding-inline: 40px;
      }
    }
    .profile-mobile-edit {
      p {
        font-size: 22px;
      }
      .profile-close-button {
        color: $white;
        position: static;
        & > span {
          margin: 0 !important;
        }
      }
      header {
        background-color: $chinese-black !important;
        margin-block-end: 50px;
        height: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 40px;
      }
      main {
        margin: 40px;
        .profile-header {
          font-family: 'Prompt', sans-serif;
          font-size: 26px;
          font-weight: 500;
        }
        > * {
          margin-block-end: 30px;
        }
      }
    }
    .profile-detail-form {
      &.isMobile {
        display: flex;
      }
      padding-block: 0;
      padding-bottom: 25px;
      & label {
        display: 'block';
        margin-bottom: 15px;
      }
    }
  }
  .profile-footer {
    justify-content: space-between;
    align-items: end;
    margin-top: 20px;
    .back-profile-button {
      color: black;
      border-color: black !important;
      font-family: 'Prompt';
    }
  }
  .datePicker-icon {
    margin-left: -0.39rem;
  }
}
.edit-button {
  font-family: 'Prompt' !important;
}

.subscribe_now-button {
  background-color: $bright-pink !important;
  width: 350px !important;
  height: 63px !important;
  margin: 20px auto !important;
  font-weight: 600 !important;
}
