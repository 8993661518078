@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.login {
  position: relative;
  z-index: 100;
  min-height: calc(100vh - 300px);
  background-color: #101010;
  &-link-text {
    @include font($color: $bright-pink, $lh: 1.25rem);
  }

  &-wrapper {
    max-width: 390px;
    padding-inline: 20px;
    margin: 0 auto;
  }

  &-step-icon {
    margin: 42px 0 48px 0;
    max-width: 100px;
    width: 100%;
    height: auto;
  }

  &-form {
    width: 100%;
  }

  &-column-layout {
    display: flex;
    flex-direction: column;

    .input-error {
      font-size: 12px;
    }
  }

  &-header {
    color: $white;
    text-align: center;
    font-family: $font-family-default;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }

  &-label {
    background-color: transparent;
    border: none;

    span {
      text-transform: none;
      color: $white;
      font-family: 'Prompt';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      overflow: visible;
    }
  }

  &-submit-button {
    margin: 2rem 0rem;
    background-color: $bright-pink;
    color: #fff;
  }

  &-mobile-header {
    display: none;
    width: 100%;
    margin-top: 1.25rem;

    .lang-selector {
      margin-right: 0;
    }
  }

  .button {
    width: 100%;
    height: 3.375rem;
    font-size: 1.125rem;
    font-weight: normal;
  }

  &-go-back {
    @include font(
      $fontFamily: $font-family-default,
      $size: toRem(20),
      $color: $white,
      $weight: 500,
      $lh: 1.75rem
    );
    cursor: pointer;
    justify-content: flex-start;
  }

  &-logo {
    width: 205px;
    max-width: 100%;
    height: auto;
    flex-shrink: 0;
  }

  &-header {
    @include font(
      $fontFamily: $font-family-default,
      $size: 1.375rem,
      $weight: 400,
      $color: $white,
      $lh: 2rem
    );
    margin: 0 auto 0;
  }

  &-input-wrapper {
    position: relative;

    .login-input div {
      @include font(
        $fontFamily: $font-family-default,
        $size: 1rem,
        $color: $white,
        $weight: 500,
        $lh: 1.75rem
      );
      padding: 0;

      a {
        @include font(
          $fontFamily: $font-family-default,
          $size: 1rem,
          $color: $anti-flash-white,
          $weight: 400,
          $lh: 1.5rem
        );
        text-decoration: underline;
      }
    }

    input {
      @include font(
        $fontFamily: $font-family-default !important,
        $size: 1.125rem !important,
        $color: $white !important,
        $lh: 1.75rem !important
      );
      height: 3.375rem;
      color: $chinese-black !important;
      border-color: $gray !important;
      border-radius: $border-radius-default !important;
      padding: 0.875rem 1.5rem !important;

      &:focus {
        border-color: $bright-pink !important;
      }

      &[type='password'] {
        font-family: Verdana !important;
        font-size: 1.6rem !important;
      }
    }
  }

  &-toggle-password-icon {
    @include equal-dimensions(1.25rem);
    position: absolute;
    top: 45%;
    right: 0.5rem;
    cursor: pointer;
  }

  .sign-in-button {
    font-weight: 600;
    box-shadow: 0px 20px 30px rgba($rose, 0.4);
    margin-top: 0.5rem;

    &[aria-disabled='true'] {
      opacity: 0.4;
      box-shadow: none;
    }
  }

  &-separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 28px auto;
    padding: 10px 0 0;

    span {
      @include font($size: 12px, $color: $white, $weight: 500, $lh: 23px);
      padding: 0 0.625rem;
    }

    &:before,
    &:after {
      content: '';
      flex: 1;
      height: 1px;
      background-color: #8a8a8a;
    }
  }

  &-terms-conditions {
    @include font($size: 14px, $color: $gray-shade, $weight: 400, $lh: 1.25rem);
    margin: 42px auto;
    text-align: center;

    div {
      height: 23px;
      width: 23px;
      box-shadow: none !important;
      transition: none !important;
      transform: none !important;
      border-radius: 5px !important;
      border: 1px solid #636363 !important;
      background-color: #101010 !important;

      svg {
        background-color: #d9d9d9 !important;
        color: $bright-pink;
        border-radius: 5px !important;
        height: 23px;
        width: 23px;
        transition: none !important;
        transform: none !important;
        margin-top: 0px !important;
      }
    }

    label {
      margin-left: 11px;
    }
  }

  &-with-social {
    width: 100%;
    white-space: nowrap;
    gap: 30px;

    .social-icon {
      cursor: pointer;
    }

    .social-button {
      margin-bottom: 19px;
      width: 55px !important;
      border-radius: 12px !important;
      height: 55px;
      gap: 8px;

      &.facebook {
        background-color: $dodger-blue;
        border-color: $dodger-blue !important;
        color: $white !important;
      }
    }

    .social-text {
      color: $white;
      font-family: $font-family-default;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }

    .social-text-ga {
      color: $chinese-black;
      font-family: $font-family-default;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }
    & > button {
      width: 100%;
    }
  }

  &-sign-up-link {
    margin-top: 1rem;
    text-align: center;

    span,
    a {
      @include font($size: 14px, $color: #979797, $lh: 1.25rem);
      display: inline-block;
      margin-right: 0.5rem;
      font-weight: 400;
      font-family: $font-family-default;
    }

    a {
      color: $white;
      text-decoration: underline;
      font-weight: 400;
      font-family: $font-family-default;
    }
  }

  &-forgot-password-link {
    color: #979797;
    text-align: right;
    font-family: 'Prompt';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
  }

  &-error {
    padding: 0.5rem;
    margin-top: 1.25rem;
    text-align: center;

    > div {
      @include font($size: 0.875rem, $lh: 1.25rem);

      svg {
        display: none;
      }
    }
  }
}

@include xs {
  .login {
    min-height: 100vh;

    &-wrapper {
      max-width: 304px;
      padding-inline: 10px;
    }

    &-mobile-header {
      display: flex !important;
    }

    .mobile-login-header {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white;
      padding: 20px 10px;

      svg {
        transform: scale(0.75);
      }
    }
  }
}

@include sm {
  .login {
    &-wrapper {
      max-width: 390px;
      margin: 0 auto;
      padding-inline: 20px;
    }
    &-mobile-header {
      display: flex !important;
    }

    .mobile-login-header {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white;
      padding: 20px 10px;

      svg {
        transform: scale(0.75);
      }
    }
  }
}

@include md {
  .login {
    &-wrapper {
      // max-width: 500px;
    }
  }
}

@include lg {
  .login {
    &-wrapper {
      // max-width: 600px;
    }
  }
}

.login_error_phone-number {
  width: 90%;
  max-width: 465px;
  height: auto;
  background-color: #101010 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiDialogContent-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .MuiTypography-h6 {
      line-height: 1;
      color: $bright-pink;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
      margin: 0;
      text-align: center;
    }
    .MuiTypography-body1 {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #fff;
    }
    .MuiButton-root {
      width: 130px;
      height: 43px;
      background-color: $bright-pink;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }

  @include xs {
    width: 100%;
    height: auto;
    .MuiDialogContent-root {
      width: 100%;
      padding: 10px;

      .MuiTypography-h6 {
        line-height: normal;
      }
    }
  }
}
