@import "../../styles/base.scss";

.maintenance-page {

  margin: 10em 0 0 3em;

  &-description {
    @include font($fontFamily: $font-family-default, $size: 1.25rem, $weight: 500, $lh: 2rem, $color: $white);
    margin: 1rem;
    @media (max-width: $mobile) {font-size: 1.0625rem;}
  }
}
