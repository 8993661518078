@import '../../styles/base.scss';

.Thumbnail {
  position: relative;
  display: flex;
  margin-top: 1rem;
  margin-right: 1rem;
  text-decoration: none;

  &-play {
    width: 90px;
    height: 90px;
    color: $white;

    @media (max-width: $mobile) {
      width: 50px;
      height: 50px;
    }
  }

  &-image {
    @include background($size: cover);
    position: relative;
    background-color: $gray;
    visibility: visible;
    opacity: 1;
    border-radius: 0.25rem;
    padding-bottom: 56.25%;
    width: -webkit-fill-available;
    border-radius: 12px;

    @media (max-width: $tablet) {
      width: -webkit-fill-available;
    }

    @media (max-width: $mobile) {
      width: -webkit-fill-available;
    }

    &-overlay-gradient {
      @include equal-dimensions(100%);
      position: absolute;
      top: 0;
      left: 0;
      visibility: visible;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:after {
      @include absolute-center();
      @include equal-dimensions(3.375rem);
      background-color: $white;
      border-radius: 50%;
      visibility: visible;
      opacity: 0;
    }

    &:hover {
      @include transition(all 0.5s);
      transform: scale(1.02);

      .Thumbnail-image-overlay-gradient {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
