@import './mixins';
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700&display=swap');

// Font
$font-family-default: 'Prompt', sans-serif;
$font-family-secondary: sans-serif;

// Colors
$black: #000000;
$white: #ffffff;
$dark-gray: #aaaaaa;
$light-gray: #d0d0d0;
$darker-grey: #1f1f1f;
$light-grey-12: rgba(217, 217, 217, 0.12);
$outer-space: #484848;
$ghost-white: #f7f8fb;
$quartz: #47494e;
$old-silver: #7f828b;
$bright-pink: #f20b7e;
$auro-metal-saurus: #6b7280bf;
$chinese-black: #101010;
$dodger-blue: #3164b5;
$anti-flash-white: #f0f0f0;
$gray: #898989;
$rose: #f10b7d;
$gray-shade: #979797;

$border-radius-default: 0.75rem;
$header-height: 80px;
$footer-height: 5rem;
$desktop-max-width: 1600px;
$desktop-medium: 1440px;
$desktop-small: 1280px;
$tablet: 1024px;
$tablet-portrait: 768px;
$mobile: 600px;

$desktop-margin: 2.125rem;
$mobile-margin: 1.25rem;

$base-font-size: 16px;

$profile-menu-item-height: 70px;
$profile-menu-item-width: 428px;
$raisin-black: #222222;

// font size

$font-size-s: 0.875rem;
$font-size-xs: 1rem;
$font-size-smx: 1.125rem;
$font-size-sm: 1.5rem;
$font-size-md: 2rem;
$font-size-mdx: 2.25rem;
$font-size-lg: 2.5rem;
$font-size-xl: 3rem;

/*
 * Converts a value from px to rem.
 * Calculates the rem value by dividing it by the base font size
 * Example:
 * toRem(18); // Result: 1.125rem
 */
@function toRem($number) {
  @return (($number * 1px) / $base-font-size) * 1rem;
}

html,
body,
#root {
  border: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  text-decoration: none;
  list-style: none;
}

body {
  background-color: $black;
  font-family: $font-family-default;
  font-size: $base-font-size;
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/*thumb*/
::-webkit-scrollbar-thumb {
  background: transparent;
}

.body-white {
  background-color: $white;
}

.flex {
  @include flexBox(
    $display: flex,
    $alignItems: center,
    $justifyContent: center
  );
}

.button {
  font-family: $font-family-default !important;
  border-radius: 0.375rem !important;
}

.pink-button {
  background-color: $bright-pink !important;
  color: $white !important;
  border-color: $bright-pink !important;
}

.white-button {
  background-color: $white !important;
  color: $black !important;
  border-color: $white !important;
  padding: 15px 45px 15px 50px !important;
}

.disabled-button {
  opacity: 0.6;
}

.main-content {
  overflow: hidden;
}

p {
  margin: 0;
}

.float-right {
  float: right;
}

.margin-top-2rem {
  margin-top: 2rem !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
