@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.ReactPlayer-container {
  height: auto;
  margin-top: 2rem;
  // margin-left: 265px;
  // margin-right: 265px;
  // width: 60%;
  // margin: auto;

  .ReactPlayer-schedule-message {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 10;
  }

  @media (max-width: $mobile) {
    margin: 0;
    .vjs-playback-rate {
      font-size: 75%;
      display: block !important;
      margin-left: 1rem;
    }
    .vjs-time-control {
      display: block !important;
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .vjs-remaining-time {
      display: none !important;
    }
  }
  @media (max-width: $tablet) {
    margin: 0;
    .vjs-playback-rate {
      font-size: 75%;
      display: block !important;
    }
  }
}

.video-js .vjs-dock-title {
  font-weight: 500;
  font-size: 30px;
  font-family: 'Prompt';
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 0
  );
}

.video-js .vjs-dock-text {
  background: rgba(0, 0, 0, 0);
  background: -webkit-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 0
  );
  padding: 1em 25% 2em 1em;
}

.video-js .vjs-big-play-button {
  display: none;
}

.ReactPlayer {
  aspect-ratio: 16 / 9;

  [class^='video-js'] {
    height: 100%;
    width: 100%;
  }
}

.companion_ad_div {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: inherit;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  position: sticky;
  flex-direction: column;
}
.bannerDiv {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: inherit;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  position: sticky;
  flex-direction: column;
}
.hidden {
  display: none;
}

#vjs-prev {
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
  margin: 5px !important;
  margin-top: 10px !important;
  -webkit-filter: drop-shadow(0 0 1em #fff);
  filter: drop-shadow(0 0 1em #fff);
}

#vjs-next {
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
  margin-top: 10px !important;
  -webkit-filter: drop-shadow(0 0 1em #fff);
  filter: drop-shadow(0 0 1em #fff);
}
.vjs-playlist-show-hide button {
  background: transparent !important;
  outline: none;
  border: transparent !important;
  border-style: none !important;
  font-family: 'Prompt' !important;
  :focus {
    border: transparent !important;
    border-style: none !important;
  }
}
.vjs-dock-description {
  display: none;
}

.video-js .customTimerOverlay {
  background-color: #050505b8;
  font-family: Prompt;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  width: 267px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.vjs-overlay-top-left {
  display: none;
}

.vjs-errors-content-container {
  font-family: 'Prompt' !important;
}

.vjs-errors-headline {
  font-family: 'Prompt' !important;
}

@media (max-width: $tablet) {
  .vjs-control-bar .vjs-progress-control {
    height: 0.25em !important;
    top: 0 !important;
  }
  .vjs-control-bar {
    height: 2em !important;
  }
  .vjs-control-bar .vjs-control {
    font-size: 75%;
  }
  .vjs-ad-control-bar .vjs-progress-control {
    height: 0.25em !important;
    top: 0 !important;
  }
  .vjs-ad-control-bar {
    height: 2em !important;
  }
  .vjs-control-bar .vjs-mute-control {
    margin-top: 5px !important;
    font-size: 75%;
  }
}

@media (max-width: $mobile) {
  .vjs-control-bar .vjs-progress-control {
    height: 0.25em !important;
    top: 0 !important;
  }
  .vjs-control-bar {
    height: 2em !important;
  }
  .vjs-ad-control-bar .vjs-progress-control {
    height: 0.25em !important;
    top: 0 !important;
  }
  .vjs-ad-control-bar {
    height: 2em !important;
  }
  .vjs-control-bar .vjs-control {
    font-size: 75%;
  }
  .vjs-control-bar .vjs-mute-control {
    margin-top: 5px !important;
    font-size: 75%;
  }
  .vjs-control-bar .vjs-picture-in-picture-control {
    display: none !important;
  }
  .vjs-control-bar .vjs-playback-rate {
    display: none !important;
  }
}

@include xs {
  .vjs-time-divider {
    margin-top: -0.4rem;
  }
}

.vjs-up-next-text,
.vjs-playlist-endscreen-control {
  display: none;
}

.vjs-menu-item {
  &:not(.vjs-selected) {
    background-color: #151b17 !important;
  }
}
