@import '../../styles/base.scss';

.VideoDetails {
  color: $white;

  &-title {
    font-size: 40px;
    line-height: 60.48px;
    font-weight: 300;
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

    @media (max-width: $tablet) {
      font-size: toRem(32);
      line-height: toRem(42);
    }

    @media (max-width: $mobile) {
      font-size: toRem(22);
      line-height: toRem(32);
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 265px;
      margin-right: 265px;

      @media (max-width: $tablet) {
        padding: 0 toRem(20);
        margin: 0;
      }
    }

    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &-icon-age {
    font-family: Prompt;
    font-size: 18px;
    font-weight: 500;
    margin-left: 1rem;
    line-height: 27px;
    width: 53px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    letter-spacing: 0px;
    text-align: center;
    background: #ffffff33;
    border-radius: 8px;
  }

  &-subtitle {
    font-family: Prompt;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0px;
    margin: 0;
    color: #ffffff;

    @media (max-width: $tablet) {
      font-size: toRem(16);
      line-height: toRem(18);
    }

    @media (max-width: $mobile) {
      font-size: toRem(12);
      line-height: toRem(16);
    }
  }
}

.Detail-wrapper-add {
  margin-right: 2rem;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  align-self: flex-end;
}

.VideoDetails-icon-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  right: 0;
  height: 60.48px;
  margin-right: 275px;
  position: absolute;
  align-self: flex-end;
  @media (max-width: $mobile) {
    margin: 0;
  }
  @media (max-width: $tablet) {
    margin-right: 0;
    padding: 20px;
  }
}

.VideoDetails-icon-share-container {
  align-self: flex-end;
}
