@import '../../styles/base.scss';

.Featured-Carousel {
  &-header {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    @include font(
      $fontFamily: $font-family-default,
      $size: 1.5rem,
      $weight: 700,
      $lh: 1.75rem,
      $color: $ghost-white
    );
  }

  &-slider {
    color: $white;
    @media (max-width: $mobile) {
      margin: 0;
    }

    .slick-list {
      cursor: pointer;
    }

    .slick-track {
      margin: 0;
    }
  }

  &-arrow {
    height: 30%;
  }

  &-arrow::before {
    color: $black;
    font-size: 33px;
  }

  &-arrow-disabled {
    display: none !important;
  }

  &-arrow-left {
    left: 10px;
  }

  &-arrow-right {
    right: 35px;
  }
}

.Featured-Carousel-main-content {
  position: relative;
}

.Featured-Carousel-header {
  font-family: Prompt;
  font-size: 30px;
  font-weight: 500;
  margin-left: 60px;
  line-height: 45px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.Featured-Carousel-content {
  // min-width: 100%;
  background-repeat: no-repeat;
  height: auto;
  // display: flex;
  margin-left: 7%;
  align-items: center;
}

.Featured-Carousel-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  vertical-align: middle;
  height: 380px;
}

.Featured-Carousel-content li {
  display: flex;
  vertical-align: middle;
  margin-right: 20px;
  height: 380px;
}

.banner_div_carousel {
  align-items: center;
  height: auto;
  margin: 10px;
  align-self: center;
  display: flex;
  justify-content: center;
}
