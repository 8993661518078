.subscription {
  position: relative;
  .subscription_status {
    .typo {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      &.title {
        color: #969696;
      }
      &.summary {
        opacity: 0;
        &.display {
          opacity: 1;
        }
      }
    }
  }
  .cancel_subscription {
    margin-left: -40px;
    width: 110%;
    text-align: center;
    bottom: 0;
    margin-bottom: -40px;
    margin-top: 60px;
    box-shadow: 0 0 0 15px black;
    button {
      width: 100%;
      height: 70px;
      font-size: 16px;
      font-weight: 500;
      color: #969696;
    }
  }
}
