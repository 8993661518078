@import '../../styles/base.scss';

.Poster-Tile-img-Result {
  flex-wrap: wrap;
  width: 380px;
  height: 250px;
  position: relative;
  align-self: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
}

.Poster-img-thumbnail {
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 218px;
  background-position: center;
  background-color: $darker-grey;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.Poster_item_title {
  font-family: Prompt;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  height: 29px;
}

.Poster-Tile-img-Result-hor {
  // flex-wrap: wrap;
  width: 100%;
  height: 182px;
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  display: flex;

  @media (max-width: $mobile) {
    // width: 318px;
    height: 182px;
  }
  @media (max-width: $tablet) {
    // width: 318px;
    height: 182px;
  }
}

.Poster-img-thumbnail-hor {
  width: 318px;
  height: 182px;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  background-position: center;
  background-color: $darker-grey;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.Poster_item_title-hor {
  width: 100%;
  color: white;
  font-family: Prompt;
  font-size: 22px;
  margin-left: 30px;
  margin-right: 10px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
}

.Poster_item_sub_title-hor {
  font-family: Prompt;
  font-size: 20px;
  font-weight: 400;
  // line-height: 30px;
  // height: 30px;
  // margin-left: 30px;
  // margin-right: 10px;
  width: 100%;
  opacity: 50%;
  color: #ffffff;
  letter-spacing: 0px;
  text-align: left;
  text-wrap: pretty;
  // margin: 20px;
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.Thumbnail-image-overlay-gradient-hor {
  align-items: center;
  justify-content: center;
  width: 318px;
  height: 182px;
  display: flex;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.Thumbnail-image-overlay-gradient-vertical {
  align-items: center;
  justify-content: center;
  width: 380px;
  height: 218px;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.Seasons-content {
  ul {
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 20px;
    }
  }
}

.img-src-poster-hor {
  width: 317.85px;
  height: 182.34px;
  display: flex;
  justify-content: center;
  align-self: center;
  position: relative;
  align-items: center;
  border-radius: 12px;
  object-fit: cover;
  @media (max-width: $tablet) {
    width: 201.96px;
    height: 115.86px;
  }
  @media (max-width: $mobile) {
    height: 82px;
    width: 120px;
  }
}

.img-src-poster {
  width: 380px;
  height: 218px;
  min-width: 'inherit';
  min-height: 'inherit';
  max-width: 'inherit';
  max-height: 'inherit';
  background-repeat: no-repeat;
  background-size: 'cover';
  background-position: 'center';
  justify-content: center;
  height: auto;
  align-self: center;
  position: relative;
  align-items: center;
  border-radius: 12px;
}
