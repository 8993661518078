@import '../../../styles/base.scss';
@import '../../../styles/mixins-mediaQuery.scss';

.more_container {
  width: 100%;
  height: auto;
}

.More {
  &-tab {
    font-size: toRem(30);
    line-height: toRem(45.36);
    font-weight: 500;
    color: #ffffff;

    @media (max-width: $tablet) {
      font-size: toRem(22);
      line-height: toRem(32);
    }

    @media (max-width: $mobile) {
      font-size: toRem(18);
      line-height: toRem(28);
    }
  }
}

.More-content ul {
  list-style: none;
  padding: 0;
  overflow-x: scroll;
  white-space: nowrap;
}

.More-content li {
  display: inline-block;
  margin-right: 20px;
  width: 192px !important;
  height: 268px !important;
}

@include xs {
  .More-content li {
    width: 100px !important;
    height: 165px !important;
  }
}

@include tabletPortrait {
  .More-content li {
    width: 192px !important;
    height: 268px !important;
  }
}
