@import '../../../styles/base.scss';

.About {
  color: $white;
  margin-block: 20px;
  @media (max-width: $mobile) {
    margin: 0;
  }
  @media (max-width: $tablet) {
    margin-top: toRem(30);
    margin: 0;
    padding: 20px;
  }

  &-tab {
    font-size: toRem(24);
    line-height: toRem(36.29);
    font-weight: 500;
    margin-left: toRem(32);
    border-bottom: 3px solid $bright-pink;
    width: fit-content;
    padding: 0 toRem(32);

    @media (max-width: $tablet) {
      font-size: toRem(24);
      line-height: toRem(32);
      padding: 0 toRem(20);
    }

    @media (max-width: $mobile) {
      font-size: toRem(18);
      line-height: toRem(28);
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    background-color: $darker-grey;
    width: 100%;
    border-radius: 12px;
    padding: toRem(32);

    @media (max-width: $tablet) {
      padding: toRem(26);
      border-radius: 0;
    }

    @media (max-width: $mobile) {
      padding: toRem(20);
    }
  }

  &-text,
  &-description {
    font-size: toRem(18) !important;
    line-height: toRem(20);
    font-weight: 400;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;

    @media (max-width: $tablet) {
      font-size: toRem(16);
      line-height: toRem(18);
    }

    @media (max-width: $mobile) {
      font-size: toRem(14);
      line-height: toRem(16);
    }
  }

  &-description {
    margin-bottom: toRem(20);
    justify-content: center;
    line-height: 1.25;
  }

  &-text {
    height: 20px;
    white-space: nowrap;
    text-decoration: underline;
  }

  &-pill {
    cursor: pointer;
    background-color: $light-grey-12;
    padding: 1rem;
    height: 30px;
    border-radius: toRem(200);
    max-width: toRem(170);
    min-width: toRem(120);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    text-decoration: underline;
    &:not(:last-child) {
      margin-right: toRem(10);
    }

    @media (max-width: $tablet) {
      padding: toRem(6) toRem(12);
    }
  }

  &-info {
    &-container {
      display: flex;
      flex-direction: column;
    }

    &-row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-label {
      font-family: Prompt;
      font-size: toRem(20);
      line-height: toRem(16);
      font-weight: 400;

      @media (max-width: $tablet) {
        font-size: toRem(18);
        line-height: toRem(20);
      }
    }
  }
}

.custom_item_flex {
  min-height: 50px;
  gap: 1rem;
}

.About-description {
  white-space: pre-wrap;
}

.About-info-group {
  // width: 40%;
  .About-info-label {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px !important;
  }

  &:not(:first-child) {
    width: 30%;
    text-overflow: ellipsis;
    padding-right: 1rem;
    display: block;
    word-wrap: break-word;
    overflow: hidden;
  }
}
