@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.forgot-password {
  background-color: #000000;
  min-height: 75vh;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-logo {
    width: 197px;
    height: 109px;
    flex-shrink: 0;
  }

  .login-header {
    @include font(
      $fontFamily: $font-family-default,
      $size: 1.375rem,
      $weight: 400,
      $color: $white,
      $lh: 2rem
    );
    margin: 0 auto 0;
  }
  &-title {
    color: $white;
    font-family: $font-family-default;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-top: 48px;
  }
  // .header-slider {
  //   margin: 20px 0 30px;
  // }
  &-sub-header {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;
  }

  @media (max-width: $mobile) {
    margin-left: 0;
    // margin: 20px;
    .forgot-password-title {
      font-size: 30px;
    }
  }
  .back-button,
  .confirm-button {
    width: 350px;
  }
}

.header-design {
  width: 112px;
  height: 47px;
  border-bottom: 1px solid black;
  position: absolute;
}

.header-slider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  @media (max-width: $mobile) {
    margin-bottom: 17px;
  }
}

.pink-slider {
  height: 6px;
  background: $bright-pink;
  border-radius: 3px;
  opacity: 1;
  flex: none;
  width: 54px;
  margin-right: 1rem;
}

.gray-slider {
  height: 6px;
  background: $light-gray;
  border-radius: 3px;
  flex-shrink: 0;
  opacity: 1;
  flex: none;
  width: 30px;
  margin-right: 1rem;
}

.password-input-field,
.error-password-input-field {
  display: flex;
  width: 350px;
  align-items: center;
  gap: 10px;
  margin-top: 38px;
  padding-bottom: 0px;
  ::placeholder {
    color: #40404080 !important;
    font-family: 'Prompt' !important;
  }
  @media (max-width: $mobile) {
    width: 100%;
  }

  input {
    border-radius: 12px !important;
    border: 1px solid #ababab !important;
    background: #f6f6f6 !important;
    padding: 15px 10px !important;
    height: 50px;
    font-family: 'Prompt' !important;
  }

  :hover,
  :active {
    border-color: $bright-pink !important;
  }

  :focus {
    border-color: $bright-pink !important;
    outline: none !important;
  }
}
.error-password-input-field {
  input {
    border-color: #ff3a44 !important;
  }

  :hover {
    border-color: #ff3a44 !important;
  }
}

.confirm-button {
  display: flex;
  width: 176px;
  gap: 4px;
  border-radius: 12px !important;
  background-color: $bright-pink !important;
  float: right;
  margin-top: 40px !important;
  height: 55px !important;
  font-family: 'Prompt' !important;

  span {
    // padding: 16px 24px !important;
    justify-content: center;
    align-items: center;
    color: $white !important;
    font-family: 'Prompt' !important;
  }
}

.disabled {
  pointer-events: none;
  background-color: #8b8b8b !important;
}

.forgot-password-form {
  // width: 405px;
  .input-label {
    text-transform: capitalize;
    padding-left: 4px;
    font-size: 14px;
    color: $chinese-black;
  }
  @media (max-width: $mobile) {
    width: 100%;
  }
}

span.error-message {
  color: #ff3a44;
  font-family: 'Prompt';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.email-sent-text {
  // position: absolute;
  // top: 522px;
  display: flex;
  color: $white;
  text-align: center;
  font-family: 'Prompt';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.please-check-email {
  // position: absolute;
  // top: 560px;
  color: rgba(151, 151, 151, 1);
  font-family: 'Prompt';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 33px;
  line-height: 23px;
}

.forgot-password-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-footer {
  justify-content: left;
}

.back-button {
  display: flex;
  font-family: 'Prompt' !important;
  max-width: 196px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 2px solid $chinese-black !important;
}
.profile-close-button {
  & > span {
    margin: 0 !important;
  }
}
@include tabletPortrait {
  .forgot-password {
    // margin-left: 179px;
  }
  .header {
    padding: 0 5%;
  }
}

@include xs {
  .forgot-password {
    height: 100vh;
  }
  .forgot-password-success {
    width: auto;
  }
  .please-check-email {
    top: 621px;
  }
  .email-sent-text {
    top: 581px;
  }
  .email-sent-text {
    font-size: 26px;
  }
  .please-check-email {
    font-size: 16px;
  }
}
