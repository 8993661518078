@import '../../styles/base.scss';

.home_container {
  width: 100vw;
  min-height: 100vh;
  position: relative;
}

.main-content-home {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.Continue-Watching-Grid-home {
  margin-left: 65px;
  position: relative;
  overflow-x: hidden;
  @media (max-width: $mobile) {
    margin-left: 15px !important;
  }
  @media (max-width: $tablet) {
    margin-left: 50px;
  }
}

.Continue-Watching-Grid-header-home {
  color: #fff;
  font-family: Prompt;
  font-size: 30px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: start !important;
  text-wrap: nowrap;
  @media (max-width: $mobile) {
    font-size: 20px;
    font-weight: 400;
  }
}

.hide_cw {
  display: none;
}

.hidden {
  visibility: hidden;
}

.marketing_holder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.margin_top {
  margin-top: 50px;
}

// .margin_top_large {
//   // margin-top: 100px;
// }
