@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.profile-page {
  transition: all 0.5s;
  margin: 200px 150px;
  .profile-page-wrapper {
    margin-bottom: 10px;
    width: 100%;
    .sidebar {
      flex: 1;
      margin-right: 50px;
    }
    .content {
      flex: 2;
      background: $raisin-black;
      padding: 10px 20px 40px;
      height: fit-content;
      overflow-x: hidden;
    }
  }
  &.isMobile {
    justify-content: center;
    align-items: baseline;
  }
}

@include xs {
  .profile-page {
    margin: 0;
    position: relative;
    z-index: 110;
    background: $black;
    flex-direction: column;
    .content {
      margin: 0;
    }
    .sidebar {
      width: 100%;
    }
  }
}
@include sm {
  .profile-page {
    margin: 200px 10px;
  }
}
