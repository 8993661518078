@import '../../styles/base.scss';

.full-col-ad-content {
  grid-column: span 6;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.banner_div {
  align-items: center;
  margin: 10px;
  height: auto;
  align-self: center;
  display: flex;
  width: 766px;
  justify-content: center;
}

.content-margin-bottom {
  margin-bottom: 16px;
}
