@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.lang-selector {
  width: 112px;
  height: 44px;
  background-color: rgba($outer-space, 0.8);
  border-radius: 1.375rem;
  padding: 0;
  margin: 1px;
  list-style-type: none;
  li {
    @include font(
      $size: 0.875rem,
      $weight: 500,
      $lh: 1.375rem,
      $color: $dark-gray
    );
    cursor: pointer;
    transition: all 0.5s;
    &::before {
      content: '•';
      display: none;
      position: relative;
      font-size: 2rem;
      padding-right: 0.5rem;
      padding-bottom: 0.25rem;
      color: $dark-gray;
    }
    &.active_th {
      color: $white;
      &::before {
        display: inline-flex;
      }
    }
    &:after {
      content: '•';
      display: none;
      position: relative;
      font-size: 2rem;
      padding-left: 0.5rem;
      padding-bottom: 0.25rem;
      color: $dark-gray;
    }
    &.active_en {
      color: $white;
      &:after {
        display: inline-flex;
      }
    }
    &.lang-separator {
      color: $dark-gray;
      padding: 0 0.375rem;
      &:before {
        display: none;
      }
      &:last-child {
        display: none;
      }
    }
  }
}
@media (max-width: $tablet) {
  .lang-selector {
    width: 0;
    height: 20px;
    background-color: rgba($outer-space, 0.8);
    border-radius: 1.375rem;
    margin-left: -10px;
    padding: 20px 50px;
  }
}
