@import '../../styles/base.scss';

.search-content {
  height: 100%;
  width: 100%;
  min-height: 80vh;
  background-repeat: repeat;
  background-color: $chinese-black;
  margin-top: 100px;
  padding-bottom: 100px;
  @media (max-width:$mobile) {
    margin-top: 0px;
   }
}

.main-content-in-search {
  margin-top: 1%;
}

.Search-Grid-Result {
  margin: 30px 20% 3% 10%;
}
