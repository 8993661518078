@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.sidebar {
  .profile_menu {
    padding: 0;
    margin: 0;
    color: $white;
    background-color: transparent;
    margin-bottom: 10px;
    .profile_menu_items {
      padding: 0;
      margin: 0;

      transition: background-color 0.5s;
      margin-bottom: 10px;
      :hover {
        background-color: #656565;
      }
      a,
      .signout-button {
        background-color: $raisin-black;
        display: block;
        width: 100%;
        height: $profile-menu-item-height;
        line-height: $profile-menu-item-height;
        color: inherit;
        padding: 0 25px;
        font-size: $font-size-smx;
        font-weight: 500;
        font-family: Prompt;
        &.active-link,
        &.signout-button:hover {
          background-color: #656565;
        }
      }
    }
    svg {
      display: block;
      height: 100%;
    }
    .signout-button {
      border: none;
      color: $bright-pink !important;
      border-radius: 0 !important;
      margin-top: 10px;
    }
  }
  .profile-close-button {
    fill: $white;
    position: absolute;
    top: 10px;
    right: 10px;
    & > span {
      margin: 0;
    }
  }

  .notf_counter_side {
    height: 16px;
    width: 16px;
    background-color: $bright-pink;
    border-radius: 50%;
    display: flex;
    align-items: center;
    left: 150px;
    justify-content: center;
    position: absolute;
    align-items: center;
    font-family: Prompt;
    font-size: 10px;
    font-weight: 500;
    color: white;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }
}

@include xs {
  .profile_menu_content {
    margin-top: 80px !important;
  }
}
