@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.privacy_settings_container {
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-repeat: repeat;
  background-color: #00000096;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.privacy_settings {
  flex-wrap: wrap;
  position: absolute;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 100px;
  top: 0;
  width: 50%;
  background-color: #101010;
  border-radius: 12px;
  pointer-events: all;
  @media (min-width: 600px) and (max-width: 768px) {
    width: 90%;
  }
}

.privacy_head {
  font-family: Prompt;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.cookie_disclaimer {
  font-family: Prompt;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.cookie_more_info {
  font-family: Prompt;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.cookie_cookie_policy {
  font-family: Prompt;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 24px;
  letter-spacing: 0px;
  margin-left: 10px;
  text-align: left;
  color: $bright-pink;
}

.consent {
  margin-top: 30px;
  font-family: Prompt;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.cookie_bg {
  background-color: #222222;
  border-radius: 12px;
  display: flex;
  margin-top: 5px;
  padding-top: 2%;
  cursor: pointer;
  padding-bottom: 2%;
  align-items: center;
}

.accordion {
  width: 100%;
}

.cookie_label {
  font-family: Prompt;
  border: 0;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: white;
  text-align: left;
}

.cookie_submit_button {
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.cookie_bg_child {
  width: 75%;
  justify-content: center;
}

.cookie_detail {
  font-family: Prompt;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  opacity: 50%;
}

.cookie_button_label {
  font-family: Prompt;
  width: 33%;
  justify-content: flex-end;
  padding-right: 0.5rem;
  font-size: 14px;
  height: fit-content;
  align-items: center;
  font-weight: 500;
  display: flex;
  line-height: 29px;
  letter-spacing: 0px;
  color: #50e393;
  padding-left: 27px;
}

.cookie_button_label_th {
  font-family: Prompt;
  width: 33%;
  justify-content: flex-end;
  // margin-right: 0.1rem;
  font-size: 14px;
  height: fit-content;
  align-items: center;
  font-weight: 500;
  display: flex;
  line-height: 29px;
  letter-spacing: 0px;
  color: #50e393;
  padding-left: 27px;
  padding-right: 0.5rem;
}

.cookie_button_settings {
  border-radius: 12px;
  background-color: #ffffff;
  font-family: Prompt;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px;
  border: 0;
  letter-spacing: 0px;
  text-align: left;
  color: #101010;
}

#accept_all_settings {
  width: 153px;
  margin-right: 10px;
  text-align: center;
}

#confirm_choice_settings {
  width: 204px;
  margin-left: 10px;
  text-align: center;
}

.toggle_holder {
  width: 25%;
  justify-content: flex-end;
  margin-right: 20px;
  display: flex;
}

.toggle {
  position: relative;
  display: block;
  width: 59px;
  height: 32px;
  border-radius: 50px;
  cursor: pointer;
}
.toggle-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.toggle-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  background: #595858;
  border-radius: inherit;
  box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 3px rgba(0, 0, 0, 0.15);
}
.toggle-label:before,
.toggle-label:after {
  position: absolute;
  top: 50%;
  color: black;
  line-height: 1;
}
.toggle-label:before {
  content: attr(data-off);
  right: 11px;
  color: #fff;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.toggle-label:after {
  content: attr(data-on);
  left: 11px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.toggle-input:checked ~ .toggle-label {
  background: #50e393;
}
.toggle-input:checked ~ .toggle-label:before {
  opacity: 0;
}
.toggle-input:checked ~ .toggle-label:after {
  opacity: 1;
}
.toggle-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  border-radius: 50%;
}
.toggle-handle:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 26px;
  height: 26px;
}
.toggle-input:checked ~ .toggle-handle {
  left: 30px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition*/
.toggle-label,
.toggle-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.hide_p_settings {
  display: none;
}

@include xs {
  .privacy_settings {
    width: 90%;
    padding: 0 20px;
  }
  .consent {
    font-size: 22.5px;
    margin-top: 12px;
  }
  .cookie_label {
    font-size: 75%;
  }
  .cookie_bg {
    height: fit-content;
  }
  .cookie_button_label {
    width: 33%;
    padding-left: 1.5rem;
    font-size: 75%;
    line-height: 21px;
  }
  .cookie_button_label_th {
    width: fit-content;
    padding-left: 3.5rem;
    font-size: 75%;
    padding-right: 0rem;
    line-height: 21px;
  }
  .privacy_head {
    font-size: 22.5px;
  }
  .cookie_disclaimer {
    font-size: 75%;
  }
  .cookie_button_settings {
    font-size: 75% !important;
  }
  .cookie_submit_button {
    flex-direction: column;
    align-items: center;
    font-size: 100% !important;
    font-family: 'Prompt' !important;
    #accept_all_settings {
      margin-bottom: 5%;
      margin-right: 0;
    }
    #confirm_choice_settings {
      margin-bottom: 5%;
      margin-right: 0;
    }
  }
  .cookie_more_info {
    font-size: 75%;
  }
  .cookie_cookie_policy {
    font-size: 75%;
  }
}
