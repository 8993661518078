@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.live-player .vjs-next-button,
.live-player .ReactPlayer-container .vjs-playback-rate,
.live-player .vjs-picture-in-picture-control {
  @media (max-width: $mobile) {
    display: none !important;
  }
}

.live-player .ReactPlayer-container .vjs-playback-rate,
.live-player .vjs-next-button {
  display: none !important;
}

@include xs {
  .vjs-time-divider {
    margin-top: -0.2rem;
  }
}
