@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.hero-slide-wrapper {
  height: 623px;
  overflow: hidden;
  display: flex;
  position: relative;
}
.Hero {
  &-slider {
    color: $white;
    margin: 0;
    .slick-list {
      cursor: pointer;
    }
    .slick-track {
      margin: 0;
    }
  }
  &-rating-icon {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 31px;
    border-radius: 8px;
  }
  &-slide {
    background-repeat: no-repeat !important;
    // background-size: 100%;
    background-size: cover;
    // background-position: center center;
    height: inherit;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
  }
  &-Tile-Info {
    margin-bottom: 40px;
    display: flex;
  }

  &-icon-age,
  &-icon-year {
    font-family: Prompt;
    font-size: 18px;
    font-weight: 500;
  }

  &-icon-age {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    padding: 5px 10px;
  }
  &-icon-year {
    margin-left: 20px;
    @media (max-width: $mobile) {
      margin-left: 50px;
    }
    @media (max-width: $tablet) {
      margin-left: 80px;
    }
  }
}
.slide-footer {
  width: auto;
  position: absolute;
  bottom: 40px;
  left: 3.75rem;
  .Hero-Tile-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
  }
  .Hero-Tile-wrapper-button {
    background-color: $bright-pink;
    cursor: pointer;
    width: 96px;
    height: 38px;
    border-radius: 12px !important;
    justify-content: center;
    align-items: center;
    .MuiButton-root {
      :hover {
        background-color: $bright-pink !important;
      }
    }
  }
}
@include xs {
  .hero-slide-wrapper {
    height: 268px;
  }

  .Hero {
    &-slide {
      background-position: center;
    }
  }
  .slide-footer {
    width: 90%;
    height: 49%;
    left: 0;
    padding-inline: 10px;
    bottom:10px;
  }
}
