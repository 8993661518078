@import '../../styles/base.scss';

.Seasons-main-content {
  margin: 20px;
}

.Seasons-header {
  font-family: Prompt;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}
