@import '../../styles/base.scss';
@import '../../styles/mixins-mediaQuery.scss';

.signup {
  font-family: Prompt;
  label {
    color: $white;
  }
  .terms_link {
    padding-right: 5px;
    a {
      color: inherit;
      text-decoration: underline;
    }
    &.next_line {
      display: block;
    }
  }
  .form-field {
    padding-bottom: 25px;
    & label {
      margin-bottom: 15px;
      display: block;
      font-family: Prompt;
    }
  }
  .date-picker-container {
    font-family: Prompt;
    position: relative;
    .date-picker {
      position: absolute;
      right: -16%;
      top: -15%;
    }
  }
}
.Mui-disabled {
  input {
    background-color: #8b8b8b;
  }
}
.MuiGrid-item {
  padding-top: 10px !important;
}

.MuiPickersLayout-actionBar {
  background-color: $bright-pink;
}
header {
  display: flex;
  font-family: Prompt;
  background-color: $chinese-black !important;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}
.header-logo {
  width: 187px;
  height: 79.86px;
}

.signup-header {
  color: $chinese-black;
  font-family: $font-family-default;
  font-size: 53px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 27px;
}

.info-header {
  color: $chinese-black;
  font-family: $font-family-default;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  padding-block: 10px;
}

.input-label {
  margin-bottom: 8px;
  font-family: Prompt !important;
  border: none;
  background-color: transparent;
  text-decoration: none;
  max-width: fit-content;

  span {
    color: $chinese-black;
    font-family: $font-family-default;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;
    text-transform: none !important;
  }
}

.signup-input-field {
  display: flex;
  // width: 405px;
  align-items: center;
  font-family: Prompt !important;
  gap: 10px;
  padding-bottom: 8px;

  input {
    border-radius: 12px !important;
    border: 1px solid #ababab !important;
    background: #f6f6f6 !important;
    padding: 15px 10px !important;
    font-family: Prompt;
    height: 50px;
  }

  :hover {
    border-color: $bright-pink !important;
  }

  :active {
    border-color: $bright-pink !important;
  }

  :focus {
    border-color: $bright-pink !important;
  }
}

.input-field {
  width: 100% !important;
  font-family: Prompt !important;
}

.signup-terms-conditions {
  outline: none;
  outline-offset: 0;
  outline-color: none;
  padding-block: 10px;
  // flex: 0.55;

  input[type='checkbox'] {
    width: 25px;
    height: 25px;
    left: -10px;
  }

  @include font($size: 23px, $weight: 500);

  div {
    height: 23px;
    width: 23px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: inset 0 0 0 1px $darker-grey !important;
    svg {
      background-color: $bright-pink;
      border-radius: 5px;
      transform: scale(1) rotate(0) translateY(-4px) !important;
    }

    :hover {
      color: $bright-pink;
    }
  }

  label {
    margin-left: 11px;
  }
}

#terms_and_conditions {
  + span {
    color: #101010 !important;
    outline: none;
    outline-offset: 0;
    outline-color: none;
    font-weight: 400;
  }
}

.confirm-header-signup {
  color: #fff;
  font-family: Prompt !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  padding-bottom: 36px;
}

.step3_confirmation {
  .confirm-header-signup {
    color: #fff;
    font-family: Prompt !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    padding-bottom: 36px;
  }
  .description {
    color: rgba(16, 16, 16, 0.6);
    font-family: Prompt !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }
}

.signup-button {
  width: 100%;
  max-width: 350px;
  height: 55px !important;
  background-color: $bright-pink !important;
  border-color: $bright-pink !important;
  border-radius: 12px !important;
  // gap: 4px !important;
  color: $white !important;

  span {
    color: #fff;
    font-family: 'Prompt';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    @include xs {
      font-size: 16px !important;
    }
  }
  &.disabled {
    opacity: 0.6;
  }
  @include xs {
    // width: 169px !important;
  }
}

.input-label-dob {
  margin-top: 8px;
  margin-bottom: 8px;
  border: none;
  background-color: transparent;
  text-decoration: none;
  max-width: fit-content;

  span {
    color: $chinese-black;
    font-family: $font-family-default;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;
  }
}

.radio-button {
  list-style-type: none;
  // margin: 25px 0 0 0;
  padding: 0;
  margin-top: 0px;
  padding-bottom: 30px;
  margin-right: 12px;
}

.radio-button li {
  float: left;
  margin: 0 5px 0 0;
  width: 122px;
  height: 50px;
  position: relative;
  margin-right: 7px;
  padding: 15px 10px;
  align-items: center;
  gap: 10px;
}

.radio-button label,
.radio-button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  background-color: #f6f6f6 !important;
  color: #404040 !important;
}

.radio-button input[type='radio'] {
  opacity: 0.011;
  z-index: 100;
}

.radio-button input[type='radio']:checked + label {
  background: #f6f6f6;
  border-radius: 12px;
}

.radio-button label {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 90;
}

.radio-button label:hover {
  background: #ddd;
}

.back-button {
  width: 100%;
  border-radius: 12px !important;
  color: $chinese-black !important;
  border-color: $chinese-black !important;
  background-color: transparent !important;
}

.Checkbox-checked-7_15_1-1E2D- .Checkbox-checkIcon-7_15_1-2r-L4 {
  background-color: $white;
}

span.error-message {
  color: #ff3a44;
  font-family: 'Prompt';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.form-button {
  margin-top: 32px;
  gap: 10px;
}
.signup-page {
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Prompt;
  background-color: $chinese-black;
  & form {
    max-width: 400px;
    label {
      text-transform: none;
      font-family: 'Prompt';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      overflow: visible;
    }
  }
  & .form-content {
    width: 100%;
    & .date-picker {
      top: 0;
      right: -40px;
    }
  }
}

.signup {
  max-width: 350px;
}

// @media
@include xs {
  .signup-page {
    min-height: 100vh;
    // margin: 50px 0%;

    padding: 0px 20px;
    font-family: Prompt;

    .signup {
      max-width: 284px;
    }

    .errorDialog {
      font-family: Prompt !important;
    }
    & form {
      max-width: 100%;
    }
    & .form-content {
      width: 85%;
      margin: auto;
    }
    .date-picker {
      right: -16% !important;
      top: 22% !important;
    }
  }
  .signup-header {
    font-size: 30px;
  }
  .form-button {
    margin: 32px;
  }
  .step3_confirmation {
    .description {
      font-size: 16px;
    }
  }
  .signup-button {
    height: 55px !important;
    // width: 169px !important;
    .span {
      font-size: 16px !important;
    }
  }

  .errorDialog {
    font-family: Prompt !important;
  }
  .info-header {
    font-size: 22px;
  }
  .mobile-login-header {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    padding: 20px 10px;

    svg {
      transform: scale(0.75);
    }
  }
}

@include sm {
  .signup-page {
    min-height: 100vh;
    padding-bottom: 266px;
  }
  .step3_confirmation {
    .description {
      font-size: 16px;
    }
  }

  .signup {
    max-width: 350px;
  }

  .mobile-login-header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    padding: 20px 10px;

    svg {
      transform: scale(0.75);
    }
  }
}

::-webkit-input-placeholder {
  font-family: 'Prompt' !important;
}

::-moz-placeholder {
  font-family: 'Prompt' !important;
}

::-ms-placeholder {
  font-family: 'Prompt' !important;
}

::placeholder {
  font-family: 'Prompt' !important;
}

.button_font_family {
  font-family: 'Prompt' !important;
  font-weight: 500 !important;
}
