@import '../../styles/base.scss';

.link-social {
  @include font($color: $dark-gray, $lh: 1.25rem);
  display: inline-block;
  text-decoration: underline;
  margin-right: 0.5rem;
}
.link-social:last-child {
  margin-right: 0;
}
