@import '../../styles/base.scss';

.Content-Grid {
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  overflow-y: scroll;
  margin-top: 100px;
  min-height: 80vh;
  padding-bottom: 100px;
  background-color: $chinese-black;
}

.Content-header {
  color: #fff;
  font-family: Prompt;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 180px;
  justify-content: start !important;
}
