@import '../../styles/base.scss';

.Tile-info-add-text {
  -webkit-line-clamp: 1;
  color: #ffffff;
  font-family: Prompt;
  font-size: 10px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  font-weight: 400;
  margin: 0;
  text-wrap: nowrap;
}

.spinner_mylist {
  @include equal-dimensions(2.25rem);
  border: 0.25rem solid $gray;
  border-top-color: $black;
  border-radius: 50%;
  animation: spin 3s linear infinite;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hide_loader {
  display: none;
}
.add_done_container {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: -4px;
}

.add_my_list {
  font-family: Prompt;
  font-size: 60px;
  font-weight: 275;
  line-height: 91px;
  margin: auto;
  letter-spacing: 0px;
  color: white;
  display: flex !important;
  align-items: center;
}

.add_my_list_done {
  font-family: Prompt;
  font-size: 60px;
  font-weight: 275;
  line-height: 91px;
  letter-spacing: 0px;
  color: white;
  padding: 2.8px 0;
}
