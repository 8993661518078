@import '../../styles/base';

.Footer {
  @include flexBox(
    $display: flex,
    $alignItems: center,
    $justifyContent: flex-start,
    $flexWrap: wrap
  );
  position: relative;
  background-color: $black;
  background: linear-gradient(90deg, $black 0%, $outer-space 100%);

  @media (max-width: $tablet) {
    @include flexBox(
      $flexDirection: column,
      $justifyContent: flex-start,
      $alignItems: center
    );
    height: auto;
  }

  &-section {
    width: 100%;
    padding: 0.5rem 2.25rem;
    display: flex;

    @media (max-width: $tablet) {
      @include flexBox($justifyContent: flex-start, $alignItems: center);
      height: auto;
    }

    &-nav {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 1rem 2.25rem;
      div {
        margin: 5px 10px;
        a {
          color: $white;
          white-space: nowrap;
          text-transform: uppercase;
          font-size: 14px;
          &:hover {
            text-underline-offset: 10px;
            text-decoration-line: underline;
            text-decoration-color: rgba(255, 255, 255, 0.3);
          }
          &.active {
            text-underline-offset: 10px;
            text-decoration-line: underline;
            text-decoration-color: #f20b7e;
          }
        }
      }
    }

    &-top {
      color: $white;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: $tablet) {
        margin-left: 0;
        text-align: center;
      }

      &-left {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }

      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;
        @media (max-width: $tablet) {
          @include flexBox(
            $flexDirection: column,
            $justifyContent: flex-start,
            $alignItems: center
          );
          height: auto;
        }
      }
    }

    &-bottom {
      background: linear-gradient(90deg, $outer-space 0%, $black 100%);
      border-top: 1px solid $old-silver;
      align-items: center;

      &-right {
        margin-left: auto;
        color: $white;
        float: right;

        @media (max-width: $tablet) {
          margin-left: 0;
          margin-top: 1rem;
          text-align: center;
        }
      }
    }
  }

  &-logo-and-motto {
    flex-direction: row;
    display: flex;
  }
  &-motto {
    font-weight: normal;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  &-address-first,
  &-address-second {
    color: $gray;
  }

  &-download-desc {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  &-main-logo {
    width: 144px;
    height: 80px;
    object-fit: contain;
    margin-right: 1rem;

    @media (max-width: $desktop-small) {
      margin-right: 1rem;
    }

    @media (max-width: $tablet) {
      width: 60px;
    }
  }
  .footer-right-container {
    align-items: flex-end;
    .button-text-container {
      white-space: pre;
      margin-left: -5px;
    }
    .footer-right-icons {
      > img {
        width: 100px;
        height: 100px;
      }
    }
  }

  &-second-logo {
    height: 8rem;
    object-fit: contain;
    margin-right: 1rem;

    @media (max-width: $desktop-small) {
      margin-right: 1rem;
    }

    @media (max-width: $tablet) {
      height: 4rem;
      object-fit: contain;
    }
  }

  &-store-link {
    @media (max-width: $tablet) {
      width: 40%;
    }
  }

  &-link-text {
    margin-right: 1rem;
    text-decoration: none;
    font-size: 14px;
    @include font($color: $dark-gray, $lh: 1.25rem);
    &:hover {
      text-decoration: underline;
    }
  }
}

.Footer .buttons-container {
  @media (max-width: $tablet) {
    flex-direction: row !important;
  }
}

.menu-title {
  font-family: Prompt;
  font-size: 12px;
  font-weight: 500;
}

.version_text_web {
  font-family: Prompt;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  left: 0;
  line-height: 15px;
  letter-spacing: 0px;
  margin: 10px;
  align-items: center;
  color: #ffffff80;
  justify-content: center;
}
.footer-parent {
  display: flex;
  flex-direction: row;
}

.Footer-store-link {
  background-color: $black !important;
  border-color: #a6a6a6;
}
.app-button {
  width: 110px !important;
  height: 37px !important;
  img {
    width: 16px;
    height: 20px;
  }
  .button-title {
    font-size: 9px;
  }
  .button-store-name {
    font-size: 11px;
  }
}
.Footer-download-desc {
  gap: 13px;
}

@media (max-width: $tablet) {
  .Footer-store-link {
    width: 70% !important;
    height: 30% !important;
    img {
      width: 20px;
    }
  }
  .buttons-container {
    .button-title {
      font-size: 6px;
      text-wrap: nowrap;
    }
    .button-store-name {
      font-size: 7px;
      text-wrap: nowrap;
    }
  }

  .Footer-section-nav div {
    margin: 5px 5px;
  }
  .buttons-container .buttons-container-dark Footer-store-link {
    width: auto;
  }
  .Footer-address-name {
    text-wrap: nowrap;
  }
}
