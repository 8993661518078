.app-layout {
  transition: all 0.5s;
}
.app-body {
  min-height: 65vh;
  // margin-top: -100px;
  // padding-top: 100px;
}

.hide_privacy {
  display: none;
}

.hide_marketing {
  display: none;
}

.notification {
  color: #000000;
  font-family: Prompt;
  font-size: 10px;
  cursor: pointer;
  padding: 0;
  font-weight: 400;
  margin: 0;
  display: flex;
  direction: row;
  text-wrap: nowrap;
}
