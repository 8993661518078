@import '../../styles/base.scss';

.img_holder {
  position: absolute;
}

.more-items {
  line-height: 25px;
  margin: 5px;
  cursor: pointer;
}

.More-label {
  text-align: center;
  align-items: center;
}

.More-label-text {
  margin: 5%;
  color: white;
  white-space: nowrap;
  font-family: Prompt;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ul_continue_watching {
  padding: 0;
  list-style: none;
  overflow-x: scroll;
  margin: 0;
  margin-bottom: 30px;
  white-space: nowrap !important;
  max-width: fit-content;
}

.ul_continue_watching .continue_watching_li {
  display: inline-block !important;
  margin-right: 20px;
}

.continue_watching_item_holder {
  width: 192px;
  height: 268px;
  position: relative;
  @media (max-width: $mobile) {
    width: 100px;
    height: 168px;
  }
}
