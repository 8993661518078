@import '../../styles/base.scss';

.Continue-Watching-Grid {
  height: 100%;
  width: 100%;
  min-height: 80vh;
  background-repeat: repeat;
  margin-top: 100px;
  padding-bottom: 100px;
  overflow-y: scroll;
  background-color: $chinese-black;
  @media (max-width:$mobile) {
   margin-top: 0px;
  }
}

.Continue-Watching-Grid-header {
  color: #fff;
  font-family: Prompt;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 250px;
  justify-content: start !important;
}

.Continue-Watching-Result-Grid-content {
  display: grid;
  margin-left: 250px;
  width: 50%;
  grid-template-columns: repeat(5, minmax(11rem, 1fr));
  grid-row-gap: 0.5rem;
  row-gap: 1rem;
  grid-column-gap: 0.3rem;
  -webkit-column-gap: 0.3rem;
  column-gap: 2rem;
  column-count: 5;
}
