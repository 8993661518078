@import '../../styles/base.scss';

.main-content-detail {
  height: 100%;
  width: 100%;
  min-height: 80vh;
  overflow-y: scroll;
  transform: translateY(100px);
  margin-bottom: 100px;
  .main-container {
    margin: auto;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}
