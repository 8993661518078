@import '../../styles/base.scss';

.Search-Grid {
  // margin-left: 250px;
  // width: 50%;
  margin-top: 1%;
  align-items: center;
  @media (max-width: $mobile) {
    margin: 10px;
  }
}

.Search-Grid-content {
  margin: 0 0 2rem 10%;
}

.Search-Result-Grid-content {
  display: grid;
  min-height: 70vh;
  grid-template-columns: repeat(5, minmax(11rem, 1fr));
  grid-row-gap: 0.5rem;
  row-gap: 1rem;
  grid-column-gap: 0.3rem;
  -webkit-column-gap: 0.3rem;
  column-gap: 2rem;
  column-count: 5;
  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, minmax(11rem, 1fr));
    margin: 2%;
    column-gap: 1%;
  }
  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, minmax(11rem, 1fr));
    margin: 2%;
    column-gap: 4%;
  }
}

.Search-Grid-header {
  font-family: 'Prompt';
  font-size: 30px;
  color: white;
  font-weight: 500;
  align-items: center;
  margin: 0 0 2rem 10%;
  display: block;
}

.querySearch {
  font-family: Prompt;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
}
